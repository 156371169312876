import React from "react";
import Img from "gatsby-image/withIEPolyfill";
import styled from "styled-components";

export default ({ fluid }) => {
    return <StyledImg title="A Picture of Braden" alt="A picture of Braden" objectFit="contain" fluid={fluid} />;
};

const StyledImg = styled(Img)`
    max-height: 100%;
    width: 100%;
`;
