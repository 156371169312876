import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery, Link } from "gatsby";

export default () => {
    const data = useStaticQuery(graphql`
        query Introduction {
            allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/introduction/" } }) {
                edges {
                    node {
                        frontmatter {
                            intro_button
                            intro_paragraph
                            intro_subheading
                        }
                    }
                }
            }
        }
    `);
    const { intro_button, intro_paragraph, intro_subheading } = data.allMarkdownRemark.edges[0].node.frontmatter;
    return (
        <Wrapper>
            <IntroWrapper>
                <Sub>{intro_subheading}</Sub>
                <h1>Braden Chamberlain</h1>
                <NMLS>NMLS #: 1658120</NMLS>
                <p>{intro_paragraph}</p>
                <ButtonWrapper>
                    <Link aria-label="Go to contact page" to="/contact/">
                        <div>{intro_button}</div>
                    </Link>
                </ButtonWrapper>
            </IntroWrapper>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    width: 60%;
    height: 100%;
    min-width: 300px;
    justify-content: center;
    p,
    h1 {
        color: ${({ theme }) => theme.textPrimary};
    }
    
    h1 {
        font-size: 6rem;
        margin: 0;

        @media only screen and (max-width: 800px) {
            font-size: 4rem;
        }
    }
    p {
        font-size: 2rem;
    }

    @media only screen and (max-width: 560px) {
        width: 100%;
    }

    @media only screen and (max-width: 400px) {
        h1 {
            font-size: 3rem;
        }
        width: 100%;
        min-width: 100px;
    }

    @media only screen and (max-width: 560px) {
        h1 {
            font-size: 2.5rem;
            p {
                font-size: 1.8rem;
            }
        }
    }
`;

const IntroWrapper = styled.div`
    width: 90%;
    max-width: 800px;
`;

const ButtonWrapper = styled.div`
    width: 100%;
    div {
        max-width: 300px;
        word-wrap: normal;
        padding: 1rem;
        margin: 0 auto;
        font-size: 2.5rem;
        text-align: center;
        color: ${({ theme }) => theme.textPrimary};
        background: ${({ theme }) => theme.colorSecondary};
        border: 3px solid ${({ theme }) => theme.colorPrimary};
        border-radius: 20px;
    }

    a {
        text-decoration: none;
    }

    &:hover {
        transform: scale(1.1);
    }

    @media only screen and (max-width: 400px) {
        div {
            font-size: 2rem;
        }
    }
`;

const NMLS = styled.span`
    font-size: 2.2rem;
    color: ${({ theme }) => theme.colorPrimary};
    @media only screen and (max-width: 400px) {
        font-size: 1.5rem;
    }
`;
const Sub = styled.span`
    font-size: 2.2rem;
    color: ${({ theme }) => theme.colorPrimary};
    @media only screen and (max-width: 400px) {
        font-size: 1.5rem;
    }
`;
