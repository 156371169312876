import React from "react";
import Footer from "./footer";
import Header from "./header";
import Hamburger from "./hamburger";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import Helmet from "react-helmet";
import { script, noscript } from "../config/facebookPixel";

export default ({ children, home, notFound }) => {
    return (
        <>
            <Helmet>
                <html lang="en" />
                <meta charSet="utf-8" />
                <script type="text/javascript">{script}</script>
                <noscript>{noscript}</noscript>
            </Helmet>
            <ThemeProvider theme={theme}>
                <Hamburger />
                <GlobalStyle notFound={notFound} />
                {!home && <Header />}
                <div>{children}</div>
                <Footer />
            </ThemeProvider>
        </>
    );
};

const theme = {
    colorPrimary: "#53be83",
    colorSecondary: "#1D6342",
    textPrimary: "white",
    hamburgerMenuBackground: "rgba(29, 29, 29, 0.9)",
};

const GlobalStyle = createGlobalStyle`
    html {
        font-size: 62.5%;
        scroll-behavior: smooth;
        font-family: Montserrat, sans-serif;
        font-weight: 400;
        background: ${({ notFound }) => (notFound ? "black" : "rgba(19, 79, 36, 0.3)")};
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: Raleway, Serif
    }
`;
