import React, { useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import axios from "axios";

export default ({ data: { form_title } }) => {
    const { register, handleSubmit, errors } = useForm();
    const [responseMessage, setResponseMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const onSubmit = (data, e) => {
        if (!loading) {
            setLoading(true);
            e.preventDefault();
            const { First, Last, Email, Phone, Message } = data;
            axios
                .post("https://formspree.io/f/mgepkvqw", { First, Last, Email, Phone, Message })
                .then((res) => {
                    console.log(res);
                    setResponseMessage("success");
                    e.target.reset();
                    setLoading(false);
                })
                .catch(() => {
                    setResponseMessage("fail");
                    setLoading(false);
                });
        }
    };
    return (
        <Container>
            <h3>{form_title}</h3>
            {responseMessage === "success" && (
                <h4>
                    Message sent successfully. I will get back in touch with you soon!{" "}
                    <span role="img" aria-label="Checkmark">
                        ✔️
                    </span>
                </h4>
            )}
            {responseMessage === "fail" && (
                <h4>
                    Message unsuccessful. Please try again!{" "}
                    <span role="img" aria-label="Sad emoji">
                        😕
                    </span>
                </h4>
            )}
            <StyledForm onSubmit={handleSubmit(onSubmit)}>
                <InputRow>
                    <RowDiv>
                        <InputWrapper>
                            <label htmlFor="first">First name:</label>
                            <input
                                id="first"
                                type="text"
                                placeholder="First name *"
                                name="First"
                                ref={register({ required: true })}
                                className={errors.First ? "error" : ""}
                            />
                        </InputWrapper>
                        {errors.First && <span>This field is required.</span>}
                    </RowDiv>
                    <RowDiv>
                        <InputWrapper>
                            <label htmlFor="last">Last name:</label>
                            <input
                                id="last"
                                type="text"
                                placeholder="Last name *"
                                name="Last"
                                ref={register({ required: true })}
                                className={errors.Last ? "error" : ""}
                            />
                        </InputWrapper>
                        {errors.Last && <span>This field is required.</span>}
                    </RowDiv>
                </InputRow>
                <InputRow>
                    <RowDiv>
                        <InputWrapper>
                            <label htmlFor="email">Email:</label>
                            <input
                                id="email"
                                type="text"
                                placeholder="Email *"
                                name="Email"
                                ref={register({ required: true, pattern: /^\S+@\S+$/i })}
                                className={errors.Email ? "error" : ""}
                            />
                        </InputWrapper>
                        {errors.Email && (
                            <span>
                                {errors.Email.type === "required" ? "This field is required." : "Invalid email format."}
                            </span>
                        )}
                    </RowDiv>
                    <RowDiv>
                        <InputWrapper>
                            <label htmlFor="phone">Phone:</label>
                            <input
                                id="phone"
                                type="text"
                                placeholder="Phone"
                                name="Phone"
                                ref={register({ required: true })}
                            />
                        </InputWrapper>
                    </RowDiv>
                </InputRow>
                <InputWrapper style={{ width: "100%" }}>
                    <label htmlFor="message">Message:</label>
                    <textarea
                        id="message"
                        placeholder="Message *"
                        name="Message"
                        ref={register({ required: true })}
                        className={errors.Message ? "error" : ""}
                    />
                </InputWrapper>
                {errors.Message && <span>This field is required.</span>}
                <button className={`button ${loading ? loading : ""}`} disabled={loading}>
                    SUBMIT
                </button>
            </StyledForm>
        </Container>
    );
};

const Container = styled.div`
    width: 50%;
    min-width: 300px;
    margin-bottom: 3rem;

    h3 {
        text-align: center;
        font-size: 2.5rem;
        margin: 0 0 1rem 0;
    }

    h4 {
        text-align: center;
        font-size: 2rem;
    }

    @media only screen and (max-width: 720px) {
        width: 90%;
    }
`;

const StyledForm = styled.form`
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 700px;

    label {
        font-size: 1.8rem;
    }

    h3 {
        color: ${(props) => props.theme.primary};
    }

    span {
        font-size: 1.5rem;
        color: red;
    }
`;

const InputRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

const InputWrapper = styled.div`
    width: 100%;
    margin: 0.5rem 0;

    input,
    textarea {
        padding: 0.5rem;
        height: 100%;
        border-radius: 5px;
        border-width: 2px;
        border-style: solid;
        border-color: ${({ theme }) => theme.colorPrimary};
        &:focus {
            outline: none;
            border-color: green;
        }
        color: black;
        box-sizing: border-box;
        background: white;
    }

    input {
        width: 100%;
    }

    textarea {
        min-height: 200px;
        width: 100%;
    }

    .error {
        border-color: red;
        color: red;

        &:focus {
            outline: none;
        }
    }

    .loading {
        opacity: 0.5;
        cursor: not-allowed;
    }
`;

const RowDiv = styled.div`
    width: 48%;
`;
