import React from "react";
import styled from "styled-components";
import { QuoteLeft, QuoteRight } from "../../assets/svg";
import { graphql, useStaticQuery } from "gatsby";

export default () => {
    const data = useStaticQuery(graphql`
        query Testimonials {
            allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/about/" } }) {
                edges {
                    node {
                        frontmatter {
                            testimonial_1_content
                            testimonial_1_name
                            testimonial_2_content
                            testimonial_2_name
                            testimonial_3_content
                            testimonial_3_name
                            testimonials_subheading
                            testimonials_title
                        }
                    }
                }
            }
        }
    `);
    const {
        testimonial_1_content,
        testimonial_1_name,
        testimonial_2_content,
        testimonial_2_name,
        testimonial_3_content,
        testimonial_3_name,
        testimonials_subheading,
        testimonials_title,
    } = data.allMarkdownRemark.edges[0].node.frontmatter;
    return (
        <Container>
            <h2>{testimonials_title}</h2>
            <h3>{testimonials_subheading}</h3>
            <TestimonialsContainer>
                <Testimonial review={testimonial_1_content} name={testimonial_1_name} />
                <Testimonial review={testimonial_2_content} name={testimonial_2_name} />
                <Testimonial review={testimonial_3_content} name={testimonial_3_name} />
            </TestimonialsContainer>
        </Container>
    );
};

const Testimonial = ({ review, name }) => {
    return (
        <Wrapper>
            <Second />
            <First />
            <Content>
                <div>
                    <StyledQuoteLeft />
                    <p>{review}</p>
                    <RightQuoteWrapper>
                        <StyledQuoteRight />
                    </RightQuoteWrapper>
                </div>
                <span>- {name}</span>
            </Content>
        </Wrapper>
    );
};

const Container = styled.div`
    text-align: center;
    margin-top: 10rem;
    h2 {
        font-size: 4rem;
        margin-bottom: 0;

        @media only screen and (max-width: 300px) {
            font-size: 2.5rem;
        }
    }
    h3 {
        font-size: 2rem;

        @media only screen and (max-width: 300px) {
            font-size: 1.8rem;
        }
    }
`;

const TestimonialsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
`;

const Wrapper = styled.div`
    position: relative;
    width: 33.3%;
    min-height: 500px;
    min-width: 300px;

    display: flex;
    align-items: center;
    justify-content: center;

    p,
    span {
        font-size: 2rem;
    }
    span {
        font-weight: bold;
    }

    @media only screen and (max-width: 950px) {
        width: 40%;
    }

    @media only screen and (max-width: 650px) {
        width: 100%;
    }

    p {
        margin: 0;
    }
`;

const First = styled.div`
    position: absolute;
    height: 80%;
    width: 80%;
    transform: rotate(-8deg);
    background: ${({ theme }) => theme.colorSecondary};
`;
const Second = styled.div`
    position: absolute;
    height: 80%;
    width: 80%;
    transform: rotate(2deg);
    background: ${({ theme }) => theme.colorPrimary};
`;

const Content = styled.div`
    position: absolute;
    text-align: left;
    overflow: auto;
    margin: auto;
    z-index: 1;
    width: 65%;
    color: ${({ theme }) => theme.textPrimary};
`;

const StyledQuoteLeft = styled(QuoteLeft)`
    width: 25px;
    height: 25px;
    fill: ${({ theme }) => theme.colorPrimary};
`;

const StyledQuoteRight = styled(QuoteRight)`
    width: 25px;
    height: 25px;
    fill: ${({ theme }) => theme.colorPrimary};
`;

const RightQuoteWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;
