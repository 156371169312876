import React from "react";
import styled from "styled-components";

export default () => {
    return <StyledFooter>&copy; 2020 Braden Chamberlain. All rights Reserved.</StyledFooter>;
};

const StyledFooter = styled.footer`
    margin: 2rem;
    text-align: center;
    font-size: 1.5rem;
`;
