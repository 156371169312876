import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image/withIEPolyfill";
import styled from "styled-components";
import { Link } from "gatsby";

export default () => {
    const data = useStaticQuery(graphql`
        query logo {
            Image: allFile(filter: { relativePath: { regex: "/shm-logo.png/" } }) {
                edges {
                    node {
                        relativePath
                        name
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }
    `);

    const [logo] = data.Image.edges;
    const {
        childImageSharp: { fluid },
    } = logo.node;
    return (
        <Wrapper>
            <LogoWrapper>
                <a
                    aria-label="Security Home Mortgage"
                    href="https://www.securityhomemortgage.com/profiles/braden-chamberlain/"
                >
                    <StyledLogo objectFit="contain" className="img" fluid={fluid} />
                </a>
            </LogoWrapper>

            <LinksWrapper>
                <Link aria-label="Home" to="/">
                    Home
                </Link>
                <Link aria-label="About" to="/about/">
                    About
                </Link>
                <Link aria-label="Contact" to="/contact/">
                    Contact
                </Link>
                <a aria-label="Braden's cell phone" href="tel:+8019600166">
                    (801) 960-0166
                </a>
            </LinksWrapper>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    height: 10%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
    a {
        color: ${({ theme }) => theme.textPrimary};
        font-size: 2.5rem;
        text-decoration: none;
        &:hover {
            transform: scale(1.1);
        }
    }

    @media only screen and (max-width: 400px) {
        height: 20%;
    }
`;

const LogoWrapper = styled.div`
    width: 50%;
    margin-left: 2rem;

    @media only screen and (max-width: 400px) {
        width: 60%;
    }
`;

const StyledLogo = styled(Img)`
    width: 80%;
    min-height: 100px;
    max-width: 500px;

    &:hover {
        transform: scale(1.05);
    }
`;

const LinksWrapper = styled.div`
    width: 100%;
    min-height: 100px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    @media only screen and (max-width: 800px) {
        display: none;
    }
`;
