import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image/withIEPolyfill";

export default () => {
    const data = useStaticQuery(graphql`
        query AboutThree {
            Image: allFile(filter: { relativePath: { regex: "/save-money.png/" } }) {
                edges {
                    node {
                        relativePath
                        name
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
            allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/about/" } }) {
                edges {
                    node {
                        frontmatter {
                            section_3_title
                            section_3_content
                        }
                    }
                }
            }
        }
    `);

    const [image] = data.Image.edges;
    const {
        childImageSharp: { fluid },
    } = image.node;
    const { section_3_title, section_3_content } = data.allMarkdownRemark.edges[0].node.frontmatter;

    return (
        <Wrapper>
            <Left>
                <h3>{section_3_title}</h3>
                <p>{section_3_content}</p>
            </Left>
            <Right>
                <StyledImg
                    title="A man explaining steps"
                    alt="A man explaining steps"
                    objectFit="contain"
                    fluid={fluid}
                />
            </Right>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

const Left = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 50%;

    h3 {
        font-size: 4rem;
        color: black;
        margin-bottom: 0;
    }

    p {
        width: 80%;
        font-size: 2rem;
    }

    @media only screen and (max-width: 650px) {
        width: 100%;

        h3 {
            font-size: 3rem;
        }
    }
`;

const StyledImg = styled(Img)`
    width: 100%;
`;
const Right = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;

    @media only screen and (max-width: 650px) {
        width: 100%;
        order: -1;
    }
`;
