import React from "react";
import styled from "styled-components";

export default ({ data: { job_title, office_phone, cell_phone, fax_number, email } }) => {
    return (
        <Container>
            <div>
                <p>Braden Chamberlain</p>
                <p>{job_title}</p>
                <p>
                    <span>NMLS #:</span> 1658120
                </p>
                <p>
                    <span>Office #:</span>{" "}
                    <a aria-label="Office phone number" href={`tel:+1${office_phone.replace(/\D/g, "")}`}>
                        {office_phone}
                    </a>
                </p>
                <p>
                    <span>Cell #:</span>{" "}
                    <a aria-label="Cell phone number" href={`tel:+1${cell_phone.replace(/\D/g, "")}`}>
                        {cell_phone}
                    </a>
                </p>
                <p>
                    <span>Fax #:</span>{" "}
                    <a aria-label="Fax number" href={`tel:+1${fax_number.replace(/\D/g, "")}`}>
                        {fax_number}
                    </a>
                </p>
                <span>Email: </span>
                <a aria-label="Braden's email" href={`mailto:${email}`}>
                    {email}
                </a>
                <ButtonWrapper>
                    <a
                        style={{ textDecoration: "none" }}
                        href="https://securityhomemortgage.mymortgage-online.com/?loanapp&siteid=2669375217&lar=bchamberlain&workFlowId=50299"
                    >
                        <div className="button">APPLY NOW</div>
                    </a>
                </ButtonWrapper>
            </div>
        </Container>
    );
};

const Container = styled.div`
    width: 50%;
    min-width: 250px;
    word-wrap: break-word;
    margin-bottom: 3rem;
    display: flex;
    justify-content: center;

    p:nth-of-type(1) {
        font-size: 2.5rem;
        font-weight: bold;
        margin: 1rem 0 0 0;
    }

    p:nth-of-type(2) {
        font-weight: bold;
        margin: 0;
    }

    p,
    a,
    span {
        font-size: 1.8rem;
    }

    span {
        font-weight: bold;
    }

    a {
        display: inline-block;
        color: #4f1713;
        word-break: break-all;
    }

    @media only screen and (max-width: 720px) {
        width: 90%;
    }
`;

const ButtonWrapper = styled.div`
    margin-top: 4rem;
    display: flex;
    justify-content: center;
`;
